import React from 'react'
import styled from 'styled-components'
import { ContentPage } from 'components/group'
import HeadSection from 'components/section/headSection'
import {
  rem,
  breakpoints,
  innerWidth,
  innerPadding,
  innerDummyPadding,
} from 'variables/_index'

export interface PageTemplateProps {
  readonly headingText: string
  readonly contentElement: JSX.Element
}

type PageTemplatePropsClassName = PageTemplateProps & {
  readonly className: string
}

function Component({
  className,
  headingText,
  contentElement,
}: PageTemplatePropsClassName) {
  return (
    <div className={className}>
      <div className="e-head">
        <HeadSection
          pageHeadProps={{
            headingText: headingText,
            headingLevel: 'h1',
          }}
        />
      </div>
      <div className="e-inner">
        <div className="e-content">
          <ContentPage contentElement={contentElement} />
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<PageTemplatePropsClassName>`
  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: ${rem(60)};
    padding-bottom: ${rem(60)};

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-top: ${rem(80)};
      padding-bottom: ${rem(80)};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }
  }
`

export const PageTemplate: (props: PageTemplateProps) => JSX.Element =
  StyledComponent

export default PageTemplate
