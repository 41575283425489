import React from 'react'
import Layout from 'layout/base'
import Seo from 'components/seo'
import PageTemplate from 'templates/pageTemplate'
import { ButtonSmall, UnderBarHeading } from 'components/atoms'

interface NotFoundPageProps {
  location: Location
}

const NotFoundPage = ({ location }: NotFoundPageProps): JSX.Element => (
  <Layout pageType="page" ctaType="type1">
    <Seo title="404: Not found" path={location.pathname} />
    <PageTemplate
      headingText="ページが見つかりません"
      contentElement={
        <>
          <UnderBarHeading text={'404 : Not Found'} headingLevel={'h1'} />
          <div className="u-text-center u-pd-t-30px">
            <p>お探しのページが見つかりませんでした。</p>
            <p>
              一時的にアクセスができない状況であるか、
              <br />
              移動、または削除された可能性があります。
            </p>

            <div className="u-pd-t-30px">
              <ButtonSmall href={'/'} value={'TOPへ戻る'} />
            </div>
          </div>
        </>
      }
    />
  </Layout>
)

export default NotFoundPage
